.Cell {
  height: 100px;
  width: 100px;
  border-radius: 1px;
  background-color: #263238;
  transition: background-color 0.7s ease;
}

.Cell-lit {
  background-color: #00bcd4;
}
